<template>
    <modal
        :name="modalName"
        :width="width"
        :maxWidth="maxWidth"
        :height="height"
        scrollable
        @closed="hideModal"
        @before-open="$emit('beforeOpen')"
        v-bind="{...$attrs, ...settings}"
        v-on="$listeners"
        adaptive
        class="previewFormModal"
        :class="{'consentModal':isConsentModal, 'cadidateFormPreview' : title == 'Form Preview'}"
    >
        <div
            class="modalContent flex flex-col h-full relative text-base-content"
            :class="{
                'modalContent--loading': loading || loadingGlobal,
                'createModal' : title == 'Create Case',
                'restModal' : (title != 'Create Case' && title != 'Form Preview'),
            }"
        >
            <div v-if="loadingGlobal" class="globalLoader">
                <loader
                    :loading="true"
                    :success="success"
                    :size="16"
                />
            </div>
            <div v-if="showHeader" class="modalContent__header  py-4 px-6 flex items-center justify-between">
                <!-- <div class="inline-flex w-full"> -->
                <h3 class="text-white-text font-semibold title-check text-lg flex items-center gap-2">
                    <slot name="title">{{ title }}</slot>
                </h3>
                <span
                    class="cursor-pointer text-white-text cross" 
                    @click.stop="hideModal"
                >
                    <!--<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="h-6 w-6" role="img"> <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>-->
                    <!-- <font-awesome-icon icon="times" size="lg"/> -->
                    <svg data-v-6d93e47d="" width="27" height="26" fill="none" xmlns="http://www.w3.org/2000/svg" class=""><path data-v-6d93e47d="" d="M7.375 6.184l12.728 12.728M7.375 19.273L20.103 6.546" stroke="#fff" stroke-width="4" stroke-linecap="round"></path></svg>
                </span>
                <!-- </div> -->
            </div>

                
            <div
                v-if="$slots.content"
                class="modalContent__content overflow-y-auto border-solid  p-4 scroll-bar flex-grow bg-card-bg"
                :class="{'bg-gray-100': darkContent}"
            >
                <slot name="content">Modal content</slot>
            </div>
            <slot></slot>
            <div class="modalContent__footer p-4 pt-0 lg:p-6 lg:pt-0 flex justify-end bg-card-bg" v-if="showFooter">
                <slot name="footer">
                    <!-- TODO remove additionalBtn -->
                    <button
                        v-if="additionalBtn"
                        v-text="additionalBtn"
                        :bg="loading ? 'var(--brand-accent-neutral-alt)' : 'var(--brand-color)'"
                        color="white"
                        hoverBg="var(--brand-hover-on)"
                        hoverText="white"
                        padding="0.4rem 0.7rem"
                        borderRadius="0.3rem"
                        class="btn btn-outline btn-info bottom-8 right-8 w-auto mr-2.5"
                        @click="$emit('alt-btn')"
                        @keyup.enter="$emit('alt-btn')"
                    />
                    <Button
                        :disabled="disabled || loading || loadingGlobal"
                        :bg="loading || loadingGlobal ? 'var(--brand-accent-neutral-alt)' : 'var(--brand-color)'"
                        class="btn bottom-8  right-8"
                        :class="submitButtonText === '' && loading ? 'w-32':'w-auto'"
                        @click="submitModal"
                        type="primary"
                        @keyup.enter="submitModal"
                        :loading="loading"
                        :text="submitButtonText"
                    >
                        <!-- <loader
                            v-if="loading"
                            class="mr-2.5"
                            :loading="true"
                            :size="4"
                        /> -->
                        {{submitButtonText}}
                    </Button>
                </slot>
            </div>
        </div>
    </modal>
</template>

<script>
import Button from "@/components/button";
import loader from "@shared/loader";

export default {
    name: "modal-content",
    components: {
        loader,
        Button,
    },
    props: {
        name: {
            type: String,
            default: () => 'new-modal',
        },
        title: {
            type: String,
            default: () => 'Modal Title',
        },
        close: { // TODO remove
            type: Boolean,
            default: () => false,
        },
        submitButtonText: {
            type: String,
            default: () => 'Save',
        },
        loading: {
            type: Boolean,
            default: () => false,
        },
        loadingGlobal: {
            type: Boolean,
            default: () => false,
        },
        success: { // TODO check and remove
            type: Boolean,
            default: () => false,
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
        darkContent: {
            type: Boolean,
            default: () => false,
        },
        width: {
            type: String,
            default: () => '50%',
        },
        maxWidth: {
            type: Number,
        },
        height: {
            type: String,
            default: () => 'auto',
        },
        settings: { // TODO remove from usage
            type: Object,
            default: () => ({}),
        },
        additionalBtn: { // TODO remove from usage
            type: String,
            default: () => '',
        },
        showFooter:{
            type:Boolean,
            default: true
        },
        showHeader:{
            type:Boolean,
            default: true
        },
        setunlockTemplate:Function,
        formData:{
            type: Object,
            default: () => ({}),
        },
        isConsentModal:{
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {};
    },
    computed: {
        modalName() {
            // return `modal-content--${this.name}`
            return this.name;
        },
    },
    mounted() {},
    methods: {
        showModal() {
            this.$modal.show(this.modalName);
        },
        hideModal() {
            this.$modal.hide(this.modalName);
            this.$emit("hidden");
            if(this.formData?.mode == 'edit'){
                this.setunlockTemplate()
            }
            if(this.modalName=="On Hold" || this.modalName=="Awaiting QC" || this.modalName=="Rejected"){
                this.$emit('setPreviousCaseStatus', true);
            }
        },
        submitModal() {
            this.$emit('submit');
        },
    },
};
</script>

<style lang="scss">

@import '@/assets/mixins/mixins.scss';
::v-deep {
    .vm--modal {
        @include scrollBar;
        border-radius: .75rem !important;
    }
}

.createModal {
    max-height: 100vh !important;
}
.restModal {
    max-height: 90vh !important;
}
.cadidateFormPreview .vm--modal{
    height: 95vh !important;
}
.modalContent {
    &--loading {
        cursor: not-allowed;
        * {
            pointer-events: none;
        }
    }

    &__header {
        @apply bg-primary;
        .title {
            color: #fff;
            margin: 0;
            font-size: 18px;
            font-weight: 500;
            font-weight: normal;
            line-height: 22px;
        }
    }
    &__footer {
        // padding-top: 20px;
        padding-bottom: 20px;
        &__button {
            min-width: 132px;
            min-height: 35px;
        }
    }
    .globalLoader {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }
}

.title-check  {
    span {
        white-space: normal;
    }
}

@media screen and (max-width: 768px) {
    .previewFormModal .vm--modal{
        margin: auto !important;
        left: 0px !important;
    }
}

// @media screen and (min-width: 300px) and (max-width: 350px) {
//   .previewFormModal .vm--modal{
//     width: 190.8px !important;
//   }
// }

@media screen and (min-width: 300px) and (max-width: 320px) {
  .previewFormModal .vm--modal{
    width: 250.8px !important;
  }
}

@media screen and (min-width: 321px) and (max-width: 350px) {
  .previewFormModal .vm--modal{
    width: 280.8px !important;
  }
}

@media screen and (min-width: 351px) and (max-width: 385px) {
  .previewFormModal .vm--modal{
    width: 310.8px !important;
  }
}

@media screen and (min-width: 386px) and (max-width: 399px) {
  .previewFormModal .vm--modal{
    width: 350.8px !important;
  }
}

@media screen and (min-width: 400px) and (max-width: 450px) {
  .previewFormModal .vm--modal{
    width: 360.8px !important;
  }
}

@media screen and (min-width: 451px) and (max-width: 500px) {
  .previewFormModal .vm--modal{
    width: 380px !important;
  }
}

@media screen and (min-width: 501px) and (max-width: 550px) {
  .previewFormModal .vm--modal{
    width: 415.8px !important;
  }
}

@media screen and (min-width: 551px) and (max-width: 600px) {
  .previewFormModal .vm--modal{
    width: 450.8px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 650px) {
  .previewFormModal .vm--modal{
    width: 485.8px !important;
  }
}

@media screen and (min-width: 651px) and (max-width: 700px) {
  .previewFormModal .vm--modal{
    width: 515.8px !important;
  }
}

@media screen and (min-width: 701px) and (max-width: 768px) {
  .previewFormModal .vm--modal{
    width: 550.8px !important;
  }
}

@media screen and (min-width: 702px){    
    .consentModal{
        .vm--modal{
            position: fixed;
            top: 50% !important; 
            left: 50% !important; 
            transform: translate(-50%, -50%) !important; 
            width: 59% !important;
            border-radius: 10px !important;
        }
    }
}
</style>